<template>
  <div id="home-lafiaccola">
    <HeaderLaFiaccola class="pink-bg"/>
    <section class="homepage pink-bg">
      <div class="home-title">
        <h1 class="font-sx">LA<span class="font-dx">FIACCOLA</span></h1>
        <div class="text-cnt">
          <div class="desc" :class="{ expanded: isShowMoreClicked }">
            <p>
              Le edizioni “La Fiaccola” nascono a Ragusa nel 1960 grazie
              all’impegno incessante di Franco Leggio. Una fitta rete di
              collaboratori e diffusori dà vita a quella che è diventata la più
              longeva delle case editrici anarchiche di lingua italiana
              <span v-show="!isShowMoreClicked">...</span>
              <span v-show="isShowMoreClicked" class="text-span">
                . La stampa di decine di opuscoletti caratterizzati da un prezzo
                bassissimo e da un’alta tiratura ha permesso una “infiltrazione”
                profonda anche in ambiti lontani dall’anarchismo. Le collane
                hanno sempre mantenuto un duplice carattere, sia nella forma
                dell’opuscolo che in quella del libro: anarchico, nel senso
                teorico, storico e dell’analisi contemporanea, e libertario,
                inteso come ambito-ponte con settori politico-culturali
                limitrofi; in questo secondo campo un posto a sé hanno avuto
                l’ateismo e l’anticlericalismo, che fanno de “La Fiaccola”
                un’esperienza editoriale quasi unica per continuità e contributi
                forniti ad una corrente non esclusivamente libertaria. Dal 1994
                Franco Leggio cede il testimone al collettivo editoriale che già
                si occupa della redazione del mensile “Sicilia libertaria”;
                l’identità e il progetto editoriale continuano come prima,
                immersi nei problemi del nostro tempo.
              </span>
            </p>
          </div>

          <button class="button-border leggi" @click="showMore">
            {{ isShowMoreClicked ? "LEGGI MENO" : "LEGGI PIÙ" }}
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L5 5L1 9" stroke="#FFFFEC" />
            </svg>
          </button>
        </div>
      </div>
    </section>

    <GalleryLaFiaccola />
    <EventiNewsLaFiaccola />
    <Footer />
  </div>
</template>

<script>
import HeaderLaFiaccola from "@/components/HeaderLaFiaccola.vue";
import GalleryLaFiaccola from "@/components/GalleryLaFiaccola.vue";
import EventiNewsLaFiaccola from "@/components/EventiNewsLaFiaccola.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "LaFiaccola",
  components: {
    HeaderLaFiaccola,
    GalleryLaFiaccola,
    EventiNewsLaFiaccola,
    Footer,
  },

  data() {
    return {
      isShowMoreClicked: false,
    };
  },

    methods: {
    showMore() {
      this.isShowMoreClicked = !this.isShowMoreClicked;

      let textSpan = document.querySelector(".text-span");
      textSpan.classList.toggle("show");
    },
  },
};
</script>
